import React, { useEffect, useContext } from 'react';

import { AuthContext } from '~contexts/auth';
import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import Button from '~components/button';

const Register = () => {
  useLayout({ displayCities: false });
  useSeo('Register');

  const { isAuthenticated, signUp, signOut } = useContext(AuthContext);

  useEffect(() => {
    if (!isAuthenticated) {
      signUp();
    }
  }, []);

  return isAuthenticated ? (
    <div className="l-container l-center">
      <Button onClick={signOut}>Logout</Button>
    </div>
  ) : null;
};

export default Register;
